import { projectFirestore } from "../../../../../../../firebase/config";

const get_branches_data_from_db = async() => {
    const docs = await projectFirestore.collection('Stock_Data').get()
    return docs.docs.map(doc=>doc.data())
}

const get_summary_branche_from_db = async({branche_num}) => {
    const map = new Map()
    const docs = await projectFirestore.collection('Stock_Data').doc(String(branche_num))
    .collection('Counts').get()
    const results = (docs.docs.map(doc=>doc.data())).sort((a,b) => 
    new Date(b.date.split('.').reverse().join('.')) - new Date(a.date.split('.').reverse().join('.')))
    for(const res of results){
        if(!map.has(res.date)){
            map.set(res.date,[res])
        }else{
            map.set(res.date,[...map.get(res.date),res])
        }
    }

    return map

}

export{
    get_branches_data_from_db,
    get_summary_branche_from_db
}