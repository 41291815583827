<template>
  <div class="counter-summary">
    <component 
        :is="state"
        :branches="branches_data" 
        :branche_summary="branche_summary"
        :branche_name="selected_branche"
        @show_branche_summary="handle_show_branche_summary"
        @back_to_branche_grid="handle_back_to_branche_grid"
    />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_branches_data_from_db,get_summary_branche_from_db} from './CounterSummary/scripts/scripts'
import { defineAsyncComponent } from '@vue/runtime-core'
export default {
    components:{
        BranchesGrid:defineAsyncComponent(()=>import('./CounterSummary/components/BranchesGrid.vue')),
        BrancheSummary:defineAsyncComponent(()=>import('./CounterSummary/components/BrancheSummary.vue')),
    },
    setup(){
        
        const state = ref('BranchesGrid')
        const branches_data = ref([])

        const branche_summary = ref(null)
        const selected_branche = ref('')

        const handle_show_branche_summary = async(branche) => {
            branche_summary.value = await get_summary_branche_from_db(branche)
            selected_branche.value = branche.branche_name
            state.value = 'BrancheSummary'
        }

        const handle_back_to_branche_grid = ()=>{
            branche_summary.value = null
            selected_branche.value = ''
            state.value = 'BranchesGrid'
        }
        const init  = async() => {
            branches_data.value = await get_branches_data_from_db()
        }


        init()

        return{
            init,
            branches_data,
            state,
            branche_summary,
            handle_show_branche_summary,
            handle_back_to_branche_grid,
            selected_branche
        }
    }
}
</script>

<style scoped>
    .counter-summary{
        width: 100%;
        height: 100%;
    }
</style>